import joinClasses from "helpers/joinClasses";

export function Title({ children, className }) {
  return (
    <h1
      className={joinClasses(
        "font-raleway-black text-[90px] leading-[115px]",
        className
      )}
    >
      {children}
    </h1>
  );
}
