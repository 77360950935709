import { Card, SubTitle, Title } from "components/atoms";
import { Template } from "components/modecules";
import { Link } from "react-router-dom";

export default function LegalNoticePage() {
  return (
    <Template>
      <div className="w-full bg-white rounded-[20px] shadow-md px-8 md:px-16 py-10 transition-all duration-300 ease-in-out max-w-[1080px] m-auto mt-10">
        <Link to="/" className="underline">
          Revenir à l'accueil
        </Link>
        <h2 className="font-raleway-black text-center text-[25px] text-secondary uppercase mt-8 mb-5">
          Mentions légales
        </h2>
        <p>
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
          pour la confiance en l'économie numérique, il est précisé aux
          utilisateurs du site Quizz relais colis l'identité des différents
          intervenants dans le cadre de sa réalisation et de son suivi.
        </p>

        <SubTitle>Edition du site </SubTitle>
        <p>
          Le présent site, accessible à l’URL quiz-relais-colis.pages.dev (le «
          Site »), est édité par :
          <br />
          <br />
          Yumaincap, société au capital de 10000 euros, inscrite au R.C.S. de
          EVRY sous le numéro RCS EVRY 843 171 703, dont le siège social est
          situé au 15 Avenue de Norvège 91140 VILLEBON SUR YVETTE, représenté(e)
          par Sophiatou NDIAYE dûment habilité(e)
        </p>

        <SubTitle>Hébergement</SubTitle>
        <p>
          Le Site est hébergé par la société cloudflare, situé 6 PL DE LA
          MADELEINE, 75008 PARIS, (contact téléphonique ou email :
          +33173015244).
        </p>

        <SubTitle>Directeur de publication </SubTitle>
        <p>Le Directeur de la publication du Site est l'Agence RJS.</p>

        <SubTitle>Nous contacter </SubTitle>
        <p>
          Par téléphone : +33184256138
          <br />
          Par email : contact@agencerjs.com
          <br />
          Par courrier : 10 Rue de Penthièvre 75008 Paris
        </p>
        <br />
        <br />
        <br />
        <p className="text-right text-xs">
          Génération des mentions légales par Legalstart.
        </p>
        <br />
      </div>
    </Template>
  );
}
