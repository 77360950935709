const questions = [
  {
    id: 1,
    name: "entry.768302327",
    type: "radio",
    title:
      "Est-il possible qu’une ou plusieurs personnes de votre équipe soit en situation de handicap sans que vous le sachiez ?",
    selectMax: 1,
    images: { left: "question.svg", right: "question-1.png" },
    answers: [
      {
        label: "Non, cela se verrait forcément",
        value: "Non, cela se verrait forcément",
      },
      {
        label:
          "Non, si ça ne se voit pas, c’est que la personne n’est pas réellement handicapée",
        value:
          "Non, si ça ne se voit pas, c’est que la personne n’est pas réellement handicapée",
      },
      {
        label:
          "Oui, le handicap peut ne pas se voir et/ou être caché par la personne",
        value:
          "Oui, le handicap peut ne pas se voir et/ou être caché par la personne",
      },
    ],
  },
  {
    id: 2,
    name: "entry.802082982",
    type: "radio",
    title: "Quelle définition donneriez-vous au handicap invisible ?",
    selectMax: 1,
    images: { left: "question.svg", right: "question-2.png" },
    answers: [
      {
        label: "Un handicap qui n’est pas toujours reconnu médicalement",
        value: "Un handicap qui n’est pas toujours reconnu médicalement",
      },
      {
        label: "Un handicap qu’une personne pense avoir mais qui n’existe pas",
        value: "Un handicap qu’une personne pense avoir mais qui n’existe pas",
      },
      {
        label:
          "Un handicap non apparent et dont on a connaissance que si la personne concernée en parle",
        value:
          "Un handicap non apparent et dont on a connaissance que si la personne concernée en parle",
      },
    ],
  },
  {
    id: 3,
    name: "entry.1056601963",
    type: "radio",
    title:
      "Selon vous, quel pourcentage de personnes en situation de handicap déclarée a un handicap invisible ?",
    selectMax: 1,
    images: { left: "question.svg", right: "question-3.png" },
    answers: [
      {
        label: "80%",
        value: "80%",
      },
      {
        label: "50%",
        value: "50%",
      },
      {
        label: "20%",
        value: "20%",
      },
    ],
  },
  {
    id: 4,
    name: "entry.376127114",
    type: "radio",
    title:
      "Parmi les situations de handicap suivantes, laquelle ne pourrait pas être classée comme handicap invisible ?",
    selectMax: 1,
    images: { left: "question.svg", right: "question-4.png" },
    answers: [
      {
        label: "Le diabète",
        value: "Le diabète",
      },
      {
        label: "La dyslexie",
        value: "La dyslexie",
      },
      {
        label: "La trisomie 21",
        value: "La trisomie 21",
      },
      {
        label: "Les séquelles d'un accident de la route",
        value: "Les séquelles d'un accident de la route",
      },
      {
        label: "La lombalgie",
        value: "La lombalgie",
      },
      {
        label: "Une amputation",
        value: "Une amputation",
      },
    ],
  },
  {
    id: 5,
    name: "entry.1328570612",
    type: "textarea",
    bonus: true,
    title:
      "Relais Colis a fait une campagne de communication sur un handicap invisible en juin 2022, quel était le sujet :",
    selectMax: 1,
    images: { left: "question.svg", right: "question-5.png" },
  },
  {
    id: 6,
    name: "entry.1261205808",
    type: "radio",
    label: "Sujet Généraliste",
    title: "La plupart des handicaps sont acquis",
    selectMax: 1,
    images: { left: "question.svg", right: "question-6.png" },
    answers: [
      {
        label: "A la naissance",
        value: "A la naissance",
      },
      {
        label: "Suite à des accidents du travail",
        value: "Suite à des accidents du travail",
      },
      {
        label: "A cause du vieillissement",
        value: "A cause du vieillissement",
      },
    ],
  },
  {
    id: 7,
    name: "entry.1702219841",
    type: "radio",
    title:
      "Parmi les personnes souffrant de handicap moteur, combien se déplacent en fauteuil roulant ?",
    label: "Sujet Généraliste",
    selectMax: 1,
    images: { left: "question.svg", right: "question-7.png" },
    answers: [
      {
        label: "73%",
        value: "73%",
      },
      {
        label: "12%",
        value: "12%",
      },
      {
        label: "Moins de 5%",
        value: "Moins de 5%",
      },
    ],
  },
  {
    id: 8,
    name: "entry.71251197",
    type: "checkbox",
    title:
      "Parmi les personnes suivantes, lesquelles peuvent bénéficier de la Reconnaissance de la Qualité de Travailleur Handicapé?",
    label: "Sujet Généraliste",
    selectMax: 1,
    images: { left: "question.svg", right: "question-8.png" },
    answers: [
      {
        label: "Un maître-nageur allergique au chlore",
        value: "Un maître-nageur allergique au chlore",
      },
      {
        label: "Une assistante administrative souffrant de diabète",
        value: "Une assistante administrative souffrant de diabète",
      },
      {
        label: "Un ingénieur malentendant",
        value: "Un ingénieur malentendant",
      },
    ],
  },
  {
    id: 9,
    name: "entry.1797090460",
    type: "radio",
    title:
      "La situation de handicap peut-elle être la conséquence de problèmes de santé courants ?",
    label: "Sujet Généraliste",
    selectMax: 1,
    images: { left: "question.svg", right: "question-1.png" },
    answers: [
      {
        label: "Oui",
        value: "Oui",
      },
      {
        label: "Non",
        value: "Non",
      },
    ],
  },
  {
    id: 10,
    name: "entry.1644917054",
    type: "radio",
    title: "Quelle est la proportion des handicaps non visibles au travail ?",
    label: "Sujet Généraliste",
    selectMax: 1,
    images: { left: "question.svg", right: "question-2.png" },
    answers: [
      {
        label: "20%",
        value: "20%",
      },
      {
        label: "50%",
        value: "50%",
      },
      {
        label: "80%",
        value: "80%",
      },
    ],
  },
  {
    id: 11,
    name: "entry.1508504836",
    type: "radio",
    label: "Handicap et Emploi",
    title:
      "Le médecin traitant peut remplir le certificat nécessaire pour une demande de reconnaissance du handicap.",
    selectMax: 1,
    images: { left: "question.svg", right: "question-3.png" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 12,
    name: "entry.316719605",
    type: "radio",
    label: "Handicap et Emploi",
    title: "Quel est l'utilité d'être reconnu travailleur handicapé ?",
    selectMax: 1,
    images: { left: "question.svg", right: "question-4.png" },
    answers: [
      {
        label: "Obtenir des places de stationnement réservées",
        value: "Obtenir des places de stationnement réservées",
      },
      {
        label:
          "Bénéficier de droits, d'aides financières et de services pour trouver ou conserver un emploi et évoluer dans l'entreprise",
        value:
          "Bénéficier de droits, d'aides financières et de services pour trouver ou conserver un emploi et évoluer dans l'entreprise",
      },
      {
        label: "Aucun intérêt",
        value: "Aucun intérêt",
      },
    ],
  },
  {
    id: 13,
    name: "entry.357468553",
    type: "radio",
    label: "Handicap et Emploi",
    title:
      "Si j’ai une Reconnaissance de la Qualité de Travailleur Handicapé on va me retirer mon permis, refuser de m’assurer pour un prêt bancaire ou augmenter ma cotisation de mutuelle",
    selectMax: 1,
    images: { left: "question.svg", right: "question-5.png" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 14,
    name: "entry.2115580875",
    type: "radio",
    label: "Handicap et Emploi",
    title:
      "Si je déclare ma Reconnaissance de la Qualité de Travailleur Handicapé à mon entreprise, tout le monde sera au courant de ma situation",
    selectMax: 1,
    images: { left: "question.svg", right: "question-6.png" },
    answers: [
      {
        label: "Vrai",
        value: "Vrai",
      },
      {
        label: "Faux",
        value: "Faux",
      },
    ],
  },
  {
    id: 15,
    name: "entry.1416793043",
    type: "radio",
    label: "Handicap et Emploi",
    title:
      "Jacqueline à une maladie, le médecin estime que ses problèmes de santé sont provisoires et durent en moyenne 3 ans Jacqueline peut-elle bénéficier d'une aide au travail ",
    selectMax: 1,
    images: { left: "question.svg", right: "question-7.png" },
    answers: [
      {
        label: "Il n'y a rien à faire, il faut patienter",
        value: "Il n'y a rien à faire, il faut patienter",
      },
      {
        label:
          "Jacqueline ne pourra pas avoir la Reconnaissance de la Qualité de Travailleur Handicapé car elle n'est pas handicatée à vie",
        value:
          "Jacqueline ne pourra pas avoir la Reconnaissance de la Qualité de Travailleur Handicapé car elle n'est pas handicatée à vie",
      },
      {
        label: "Jacqueline peut faire une demande de Reconnaissance de la Qualité de Travailleur Handicapé",
        value: "Jacqueline peut faire une demande de Reconnaissance de la Qualité de Travailleur Handicapé",
      },
    ],
  },
];

export default questions;
