import joinClasses from "helpers/joinClasses";
import Button from ".";

export function PrimaryButton({ className, children, onClick, ...props }) {
  return (
    <Button
      className={joinClasses("bg-secondary text-white", className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}
