import joinClasses from "helpers/joinClasses";

export default function Button({ className, children, onClick, ...props }) {
  return (
    <button
      className={joinClasses(
        "rounded-full cursor-pointer font-raleway-black text-[12px] tracking-[4px] md:tracking-[5px] px-10 py-3 hover:tracking-[3px] transition-all duration-200",
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}
