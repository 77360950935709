import { Card, PrimaryButton, Title } from "components/atoms";

export default function Welcome({ onNext = () => {} }) {
  const handleClick = (event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <div className="flex justify-between w-full w-full">
      <div
        className="w-full bg-no-repeat bg-left-bottom "
        style={{
          backgroundImage: `url("/assets/icons/welcome-left.svg")`,
        }}
      />
      <Card className="mt-16 z-10 flex flex-col justify-around">
        {/* TITLE BLOCK */}
        <div className="relative w-fit mx-auto">
          <img
            src="/assets/icons/ask.svg"
            className="absolute -left-20 bottom-0 -z-10"
            alt=""
          />
          <Title className="text-center z-10 border-b border-black">Quiz</Title>
          <img
            src="/assets/icons/red-circle.svg"
            className="absolute -right-8 top-2 -z-10"
            alt=""
          />
        </div>

        <h2 className="font-raleway-bold text-center text-[25px] text-primary uppercase mt-8 mb-5">
          Handicap Invisible
        </h2>

        <p className="text-[#707070] text-center font-raleway-regular text-[20px] leading-[24px]">
        En cette Semaine Européenne de l’Emploi des Personnes en situation de Handicap, nous vous proposons ce jeu de sensibilisation qui vous invite à découvrir les enjeux et notions clés sur le handicap invisible.
        </p>
        <br/>
        <ul className="text-[#707070] text-center font-raleway-regular text-[20px] leading-[24px]">
          <li>● Qu'est-ce que le handicap invisible ?</li>
          <li>● Quelles réalités ce terme recouvre ?</li>
        </ul>
        <p className="text-[#707070] text-center font-raleway-regular text-[20px] leading-[24px]">
          A vous de jouer
        </p>
        <div className="flex justify-center mt-10">
          <PrimaryButton onClick={handleClick}>Commencer</PrimaryButton>
        </div>
      </Card>
      <div
        className="w-full bg-no-repeat bg-left-bottom"
        style={{
          backgroundImage: `url("/assets/icons/welcome-right.svg")`,
        }}
      />
    </div>
  );
}
