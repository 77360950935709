import { Route, Routes } from "react-router";

import QuizzPage from "quiz/quiz.page";
import RulesPage from "quiz/rules.page";
import LegalNoticePage from "quiz/legals.page";
import SuccessPage from "quiz/success.page";
import { useEffect } from "react";

export default function RootContainer() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          !localStorage.getItem("participation") ? (
            <QuizzPage />
          ) : (
            <SuccessPage />
          )
        }
      />
      <Route path="/reglement" element={<RulesPage />} />
      <Route path="/mentions-legales" element={<LegalNoticePage />} />
      <Route path="/debug-develop" element={<DebugMode />} />
    </Routes>
  );
}

const DebugMode = () => {
  useEffect(() => {
    if (process.env.REACT_APP_PRODUCTION === "false") {
      localStorage.removeItem("participation");
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.href = "/";
  }, []);
  return <div></div>;
};
