import {
  Checkbox,
  PrimaryButton,
  Radio,
  SecondaryButton,
  Textarea,
} from "components/atoms";
import { QuestionTemplate } from "components/modecules";
import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";
import questions from "utils/questions";

export default function Questions({ show = false }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isAwnserd, setIsAwnserd] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setCurrentQuestion(currentQuestion + 1);
    setIsAwnserd(false);
    window.scrollTo(0, 0);
  };

  const handleAwnser = (event) => {
    setIsAwnserd(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const getDescription = (type) => {
    switch (type) {
      case "checkbox":
        return "Plusieurs réponses possibles";
      case "radio":
        return "Une seule réponse possible";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (questions[currentQuestion].bonus) {
      setIsAwnserd(true);
    }
  }, [currentQuestion]);

  return (
    <div className={show ? "block" : "hidden"}>
      {questions.map((question, index) => (
        <QuestionTemplate
          question={question}
          active={index === currentQuestion}
          position={currentQuestion + 1}
          key={index}
        >
          <p className="mt-10 font-raleway-regular text-[14px] opacity-50 mb-5">
            {getDescription(question.type)}
          </p>
          <div>
            {question.answers ? (
              question.answers?.map((answer, index) => {
                switch (question.type) {
                  case "checkbox":
                    return (
                      <Checkbox
                        type={question.type}
                        name={question.name}
                        label={answer.label}
                        value={answer.value}
                        onChange={handleAwnser}
                        key={question.name + index}
                      />
                    );
                  case "radio":
                    return (
                      <Radio
                        type={question.type}
                        name={question.name}
                        label={answer.label}
                        value={answer.value}
                        onChange={handleAwnser}
                        key={question.name + index}
                      />
                    );

                  default:
                    return null;
                }
              })
            ) : (
              <Textarea
                type={question.type}
                name={question.name}
                key={question.name + index}
                placeholder="Écrivez votre réponse ici"
              />
            )}
          </div>
          {isAwnserd && index + 1 < questions.length ? (
            <SecondaryButton
              onClick={handleClick}
              className={joinClasses(
                "absolute bottom-0 right-1/2 md:right-14 translate-y-1/2 translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal",
                !isAwnserd &&
                  "bg-[#DCE892] !cursor-not-allowed pointer-events-none"
              )}
            >
              Question suivante {">"}
            </SecondaryButton>
          ) : (
            isAwnserd &&
            index + 1 === questions.length && (
              <PrimaryButton
                type="submit"
                className={joinClasses(
                  "absolute bottom-0 right-1/2 md:right-14 translate-y-1/2 translate-x-1/2 md:translate-x-0 whitespace-nowrap md:whitespace-normal",
                  !isAwnserd &&
                    "bg-[#DCE892] !cursor-not-allowed pointer-events-none"
                )}
              >
                Terminer
              </PrimaryButton>
            )
          )}
        </QuestionTemplate>
      ))}
    </div>
  );
}
