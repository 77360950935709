import { Card, SubTitle, Title } from "components/atoms";
import { Template } from "components/modecules";
import { Link } from "react-router-dom";

export default function RulesPage() {
  return (
    <Template>
      <div className="w-full bg-white rounded-[20px] shadow-md px-8 md:px-16 py-10 transition-all duration-300 ease-in-out max-w-[1080px] m-auto mt-10">
        <Link to="/" className="underline">
          Revenir à l'accueil
        </Link>
        <h2 className="font-raleway-black text-center text-[25px] text-secondary uppercase mt-8 mb-5">
          Règlement du jeu-concours "Quiz handicap"
        </h2>

        <SubTitle>Article 1 - Société organisatrice</SubTitle>
        <p>
          Yumaincap, entreprise adaptée spécialisée en conseils RH et politique
          handicap, organise pour le compte de Relais Colis, un jeu-concours qui
          se déroulera durant la Semaine Européenne pour l’Emploi des Personnes
          en situation de Handicap du 14 novembre 09H au 20 Novembre 2022 - 12h.
        </p>

        <SubTitle>Article 2 - Objet</SubTitle>
        <p>
          Ce quizz est gratuit et sans obligation d’achat. Il se déroule
          exclusivement sur internet. Les participants devront répondre en ligne
          à 1 questionnaire de 15 questions. Les vainqueurs seront tirés au
          sort parmi les meilleurs résultats.
        </p>
        <SubTitle>Article 3 - Descriptif du jeu</SubTitle>
        <SubTitle className="text-[14px] mt-2">
          3.1 Modalités de participation
        </SubTitle>
        <p>Pour participer à ce jeu-concours, les candidats doivent :</p>
        <br />
        <p>
          1°) être une personne physique et majeure
          <br />
          2°) ouvrir le lien Microsoft Forms disponible via le site xxxx ou en
          scannant le QR CODE disponible sur les affiches
          <br />
          3°) répondre à chacune des questions, en cochant, parmi les réponses
          proposées, la réponse estimée exacte, et de valider sa participation.
          Chaque questionnaire doit être répondu à toutes les questions pour
          valider sa participation. Il est nécessaire de posséder une adresse de
          courrier électronique pour valider la participation au concours.
        </p>
        <br />
        <p>Une seule participation par personne.</p>
        <br />
        <p>
          Une personne qui participerait plusieurs fois sera exclue du tirage au
          sort.
        </p>
        <br />
        <SubTitle className="text-[14px] mt-2">3.2 Tirage au sort</SubTitle>
        <p>
          Un tirage au sort désignant les gagnants du jeu sera effectué par un
          système électronique aléatoire le vendredi 20 novembre, parmi les
          participants ayant validé leur participation pendant la période
          d'organisation du jeu.
        </p>

        <SubTitle>Article 4 - Dotation</SubTitle>
        <p>
          <b>4.1</b> Le jeu est globalement doté de 2 (deux) lots, chèque cadeau
          d’une valeur globale de 65 euros TTC chacun :
        </p>
        <br />
        <p>
          <b>4.2</b> Les gagnants seront informés de leur gain par le biais du
          mail renseigné lors de leur participation dans un délai d’une semaine
          à compter de la clôture du jeu.
          <br />
          Les gagnants devront alors prendre contact avec Yumaincap afin de
          recevoir leur lot par voie postale.
          <br />
          <br />
          En aucun cas, il ne pourra être exigé de contrepartie financière en
          substitution du lot offert qui ne sera ni repris, ni échangé. Dans les
          cas où le lot mis en jeu ne serait plus disponible pour des raisons
          indépendantes de la volonté de Yumaincap cette dernière s’engage à
          offrir un lot de nature et de valeur équivalente.
          <br />
          <br />
          Les gagnants disposent d’un délai d’un mois à compter de l’envoi par
          la Yumaincap du message l’informant de leur gain pour réclamer leur
          lot.
        </p>

        <SubTitle>Article 5</SubTitle>
        <p>
          <b>5.1</b> Toute participation ne respectant pas les règles fixées à
          l’article 3.1 ci-dessus, toute multi participation  ou toute
          participation qui n’aura pas été faite durant la période
          d’organisation du jeu sera considérée comme nulle et ne sera pas prise
          en considération pour le jeu. <br />
          L'utilisation de robots ou de tout autres procédés similaires
          permettant de participer au jeu de façon mécanique ou autre est
          proscrite, la violation de cette règle entraîne l'élimination
          définitive de son réalisateur et /ou utilisateur.
          <br />
          <br />
          La société organisatrice pourra annuler tout ou partie du jeu s'il
          apparaît que des fraudes sont intervenues sous quelque forme que ce
          soit, notamment de manière informatique dans le cadre de la
          participation au jeu. Elle se réserve, dans cette hypothèse, le droit
          de ne pas attribuer la dotation aux fraudeurs et / ou de poursuivre
          devant les juridictions compétentes les auteurs de ces fraudes.
        </p>
        <br />
        <p>
          <b>5.2</b> La société organisatrice se réserve, notamment en cas de
          force majeure, le droit d'écourter, prolonger, suspendre, modifier ou
          annuler son jeu.
        </p>
        <br />
        <p>
          <b>5.3</b> La responsabilité de la Société Organisatrice ne saurait
          être engagée du fait d'une destruction totale ou partielle des
          informations sur la participation résultant d’opérations techniques ou
          d’incidents survenant sur les Réseaux ou par tout cas fortuit ou tout
          événement de force majeure qui empêcherait l'organisation du jeu.
        </p>
        <br />
        <p>
          <b>5.4</b> Les informations à caractère personnel recueillies par la
          Société Organisatrice lors de la participation sont nécessaires au
          traitement de la participation et à l’organisation du jeu et seront
          enregistrées jusque fin novembre 2022.
        </p>
        <br />
        <p>
          <b>5.5</b> La participation au présent jeu implique l'acceptation par
          les participants, sans restriction ni réserve, du présent règlement.
        </p>
        <SubTitle>Article 6 - Dépôt du règlement</SubTitle>
        <p>
          Le présent règlement est disponible en intégralité pendant toute la
          durée du jeu.
        </p>
        <SubTitle>Article 7 - Litiges</SubTitle>
        <p>
          Tout litige pouvant intervenir sur l'interprétation ou obligation du
          présent règlement sera expressément soumis à l'appréciation des
          Tribunaux compétents, même en cas de pluralité de défendeurs, d'appel
          en garantie ou de référé.
        </p>

        <br />
        <br />
        <br />
        <p className="text-right">Fait à Paris, le 25 janvier 2022.</p>
        <br />
      </div>
    </Template>
  );
}
