import { Link } from "react-router-dom";

export function Header() {
  return (
    <header className="h-[100px] w-full bg-white flex items-center justify-center shadow">
      <Link to="/">
        <img
          src="/assets/brand/logo.svg"
          alt="relais colis"
          className="max-w-[200px] w-full"
        />
      </Link>
    </header>
  );
}
